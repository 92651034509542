import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useTonConnectUI, useTonAddress, useIsConnectionRestored } from "@tonconnect/ui-react";
import { Proofs } from "./utils";
import { useSend } from "./utils/useSend";
export default function App() {
  const [isGameOver, setIsGameOver] = useState(false);
  const [userName, setUserName] = useState();
  const [score, setScore] = useState();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const rawAddress = useTonAddress(false);
  const friendlyAddress = useTonAddress(true);
  const {sendTransaction} = useSend();
  const connectionRestored = useIsConnectionRestored();      
  function handleCacheControl(url) {
    if (url.match(/\.data/) || url.match(/\.bundle/)) {
      return "must-revalidate";
    }
    return "no-store";
  }
  const { unityProvider, sendMessage, addEventListener, loadingProgression, removeEventListener, isLoaded } =
    useUnityContext({
      loaderUrl: "build/Web/Build/Web.loader.js",
      dataUrl: "build/Web/Build/Web.data.unityweb",
      frameworkUrl: "build/Web/Build/Web.framework.js.unityweb",
      codeUrl: "build/Web/Build/Web.wasm.unityweb",
      streamingAssetsUrl: "build/Web/StreamingAssets",
      cacheControl: handleCacheControl,
    });

  const handleConnectWallet = useCallback((tonProof) => {
        requestConnection(tonProof);
  }, []);
  const handleConnectWalletEmailAndPassword = useCallback((tonProof, email, password) => {
      setEmail(email);
      setPassword(password);
      requestConnection(tonProof);
  }, []);

  const handleDisconnectWallet = useCallback(() => {
    if(tonConnectUI.connected){
      tonConnectUI.disconnect();
      localStorage.removeItem('session');
      setEmail(null);
      setPassword(null);
    }
  }, []);
  const requestConnection = async (tonProof) => {    
    if(tonConnectUI.connected){
      await tonConnectUI.disconnect();
    }
    tonConnectUI.setConnectRequestParameters({state: 'ready', value: {
        tonProof: tonProof
    }});        
    tonConnectUI.openModal();    
  }

  const _checkProof = async () => {    
      const session = await Proofs.verifyProof(tonConnectUI.wallet, rawAddress, friendlyAddress);                   
      if(!session?.session){
        return;
      }
      localStorage.setItem('session', session?.session);
      sendMessage("ReactCommunicator", "Connected", session.session);
  }
  const _checkProofWithEmailAndPassword = async (email, password) => {    
    const session = await Proofs.verifyProofWithEmailAndPassword(tonConnectUI.wallet, rawAddress, friendlyAddress, email, password);                   
    if(!session?.session){
      return;
    }
    localStorage.setItem('session', session?.session);
    sendMessage("ReactCommunicator", "Connected", session.session);
}

  const handleSendTransaction = async (productId, itemId, tonPrice, payload, signature) => {    
    try{
      await sendTransaction('0QDKgna_MN_rArMTo3fU68QMxL3L_5ziWZ1_fJuHRrh60mpt', Number(tonPrice) * 1e9, payload);         
      sendMessage("ReactCommunicator", "TransactionSent", 0);
    }catch(e){
      console.log(e);
      sendMessage("ReactCommunicator", "TransactionSent", 1);
    }
  }

  useEffect(() => {        
      if(!rawAddress){
          return;
      }     
      if(email != null && password != null){
        _checkProofWithEmailAndPassword(email, password);
        return;
      }     
      _checkProof();
  }, [rawAddress]);   
  useEffect(() => {
    addEventListener("ConnectWallet", handleConnectWallet);
    return () => {
      removeEventListener("ConnectWallet", handleConnectWallet);
    };
  }, [addEventListener, removeEventListener, handleConnectWallet]);
  useEffect(() => {
    addEventListener("ConnectWalletAndEmailAndPassword", handleConnectWalletEmailAndPassword);
    return () => {
      removeEventListener("ConnectWalletAndEmailAndPassword", handleConnectWalletEmailAndPassword);
    };
  }, [addEventListener, removeEventListener, handleConnectWallet]);  
  useEffect(() => {
    addEventListener("DisconnectWallet", handleDisconnectWallet);
    return () => {
      removeEventListener("DisconnectWallet", handleDisconnectWallet);
    };
  }, [addEventListener, removeEventListener, handleDisconnectWallet]);

  useEffect(() => {
    addEventListener("SendTransaction", handleSendTransaction);
    return () => {
      removeEventListener("SendTransaction", handleSendTransaction);
    };
  }, [addEventListener, removeEventListener, handleSendTransaction]); 
  useEffect(() => {    
    if(connectionRestored == true && isLoaded == true){      
      var session = localStorage.getItem("session");
      if(session != null && session != undefined && typeof session === 'string' && session.length > 0){        
        sendMessage("ReactCommunicator", "Connected", session);
      }
    }
  }, [connectionRestored, isLoaded])

  return (
    <Fragment>
      {!isLoaded && <div className="loading-bar" style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh", backgroundColor: "black", color: "white"}}>                
        <progress value={loadingProgression} max="1"></progress>
      </div>}
      <Unity style={{ width: "100vw", height: "100vh" }}  unityProvider={unityProvider} />
    </Fragment>
  );
}