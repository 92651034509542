import { Account, TonProofItemReplySuccess, Wallet, WalletInfoWithOpenMethod } from "@tonconnect/ui-react";

export class Proofs{
    
    static async verifyProof(wallet, rawAddress, friendlyAddress){
      const w = wallet;        
      if (w?.connectItems?.tonProof && 'proof' in w.connectItems.tonProof) {          
          const session = await Proofs.checkProof(w?.connectItems?.tonProof?.proof, w.account, rawAddress, friendlyAddress);            
          if(session){              
              return session;
          }
      }      
      return false;
    }
    static async verifyProofWithEmailAndPassword(wallet, rawAddress, friendlyAddress, email, password){
      const w = wallet;        
      if (w?.connectItems?.tonProof && 'proof' in w.connectItems.tonProof) {          
          const session = await Proofs.checkProofEmailAndPassword(w?.connectItems?.tonProof?.proof, w.account, rawAddress, friendlyAddress, email, password);            
          if(session){              
              return session;
          }
      }      
      return false;
    }
    static async checkProof(proof, account, rawAddress, friendlyAddress){
        try {
          const reqBody = {
            rawAddress: rawAddress,
            address: account.address,
            network: account.chain,
            public_key: account.publicKey,
            proof: {
              ...proof,
              state_init: account.walletStateInit,
            },
          };
          const request = await fetch(`${process.env.REACT_APP_API_URL}/ton/check_proof`, {
            method: 'POST',
            body: JSON.stringify(reqBody),
            headers: {
              'Content-Type': 'application/json'
            },
          });                  
          console.log(request.status);
          if(!request.ok){
            return null;
          }
          const response = await request.json();          
          console.log(response);
          return {session: response.data.session, tonNFTs: response.data.tonNFTs};          
        } catch (e) {
          console.log('checkProof error:', e);
          return null;
        }
      }
    
      static async checkProofEmailAndPassword(proof, account, rawAddress, friendlyAddress, email, password){
        try {
          const reqBody = {
            email: email, 
            password: password,
            rawAddress: rawAddress,
            address: account.address,
            network: account.chain,
            public_key: account.publicKey,
            proof: {
              ...proof,
              state_init: account.walletStateInit,
            },
          };
          const request = await fetch(`${process.env.REACT_APP_API_URL}/ton/check_proof`, {
            method: 'POST',
            body: JSON.stringify(reqBody),
            headers: {
              'Content-Type': 'application/json'
            },
          });                  
          console.log(request.status);
          if(!request.ok){
            return null;
          }
          const response = await request.json();          
          console.log(response);
          return {session: response.data.session, tonNFTs: response.data.tonNFTs};          
        } catch (e) {
          console.log('checkProof error:', e);
          return null;
        }
      }
}


