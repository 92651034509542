import { SendTransactionRequest, useTonConnectUI } from "@tonconnect/ui-react";

export function useSend(){
    const [tonConnectUI] = useTonConnectUI();

    const sendTransaction = (address, amount, payload) => {
        const transaction = {
            messages: [
                {
                    address: address, // destination address
                    amount: amount.toString(), //Toncoin in nanotons
                    payload: payload
                }
            ], 
            validUntil: Date.now() + 1000 * 60 * 60,
        }
        return tonConnectUI.sendTransaction(transaction);
    }

    return {sendTransaction}
    
}